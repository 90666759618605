export type TCategory = string

export type TThing = {
  name: string
  category: TCategory
  exclude?: TCategory[]
}

export type TQuestion = {
  thing: TThing
  otherCategory: TCategory
  leftIsCorrect: boolean
}

export type TCategoryExclusion = {
  category: TCategory
  exclude: TCategory[]
}

export const CategoryExclusions: Record<TCategory, TCategory[]> = {
  "Australian cattle breed": ["PDO region-specific foodstuff"],
  "British railway station": ["London Underground station", "English village", "English town", "Scottish village", "Scottish town"],
  "English village": ["English town", "British railway station", "Scottish village", "Scottish town"],
  "English town": ["English village", "British railway station", "Scottish village", "Scottish town"],
  "English football stadium": ["British railway station"],
  "IKEA cutlery tray": ["IKEA shelving", "IKEA homeware"],
  "IKEA homeware": ["IKEA shelving", "IKEA cutlery tray"],
  "IKEA shelving": ["IKEA homeware", "IKEA cutlery tray"],
  "Lake District lake": ["Lake District reservoir", "British railway station", "English village", "English town"],
  "Lake District reservoir": ["Lake District lake", "British railway station", "English village", "English town"],
  "London Underground station": ["British railway station", "English village", "English town"],
  "PDO region-specific foodstuff": ["Australian cattle breed"],
  "Scottish village": ["Scottish town", "British railway station", "English village", "English town"],
  "Scottish town": ["Scottish village", "British railway station", "English village", "English town"],
  "World War II fighter plane": ["World War II bomber plane", "World War II flying boat"],
  "World War II bomber plane": ["World War II fighter plane", "World War II flying boat"],
  "World War II flying boat": ["World War II fighter plane", "World War II bomber plane"],
}

export const Things: TThing[] = [
  // Geography
  { name: "Cape Verde", category: "Archipelagic country" },
  { name: "Comoros", category: "Archipelagic country" },
  { name: "Kiribati", category: "Archipelagic country" },
  { name: "Marshall Islands", category: "Archipelagic country" },
  { name: "Solomon Islands", category: "Archipelagic country" },

  { name: "Ashby de la Zouch", category: "English town" },
  { name: "Coln Rogers", category: "English village" },
  { name: "Cross in Hand", category: "English village" },
  { name: "Guiting Power", category: "English village" },
  { name: "Guthram Gowt", category: "English village" },
  { name: "Haselbury Plucknett", category: "English village" },
  { name: "Kippax", category: "English village" },
  { name: "Kirkby Overblow", category: "English village" },
  { name: "Lower Slaughter", category: "English village" },
  { name: "Marsh Gibbon", category: "English village" },
  { name: "Mavesyn Ridware", category: "English village" },
  { name: "Port Sunlight", category: "English village" },
  { name: "Saffron Walden", category: "English town" },
  { name: "Stelling Minnis", category: "English village" },
  { name: "Westward Ho!", category: "English village" },
  { name: "Winnersh", category: "English village" },

  { name: "Bishopbriggs", category: "Scottish town" },
  { name: "Blair Atholl", category: "Scottish village" },
  { name: "Lossiemouth", category: "Scottish town" },
  { name: "Mellon Charles", category: "Scottish village" },

  { name: "Bigland Tarn", category: "Lake District lake" },
  { name: "Cogra Moss", category: "Lake District reservoir" },
  { name: "Devoke Water", category: "Lake District lake" },
  { name: "Gurnal Dubs", category: "Lake District lake" },
  { name: "Innominate Tarn", category: "Lake District lake" },

  { name: "Cromarty", category: "Shipping forecast region" },
  { name: "German Bight", category: "Shipping forecast region" },
  { name: "Lundy", category: "Shipping forecast region" },
  { name: "North Utsire", category: "Shipping forecast region" },

  { name: "Baton Rouge", category: "US state capital", exclude: ["Death metal band"] },
  { name: "Des Moines", category: "US state capital" },
  { name: "Little Rock", category: "US state capital" },

  { name: "Cueva de las Manos", category: "World Heritage Site in South America" },
  { name: "Fray Bentos Cultural-Industrial Landscape", category: "World Heritage Site in South America" },
  { name: "Machu Picchu", category: "World Heritage Site in South America" },
  { name: "Qhapaq Ñan", category: "World Heritage Site in South America" },
  { name: "Valongo Wharf", category: "World Heritage Site in South America" },

  // Transport
  { name: "Brent Cross", category: "London Underground station" },
  { name: "Dollis Hill", category: "London Underground station" },
  { name: "Mornington Crescent", category: "London Underground station" },
  { name: "Stepney Green", category: "London Underground station" },
  { name: "Theydon Bois", category: "London Underground station" },

  { name: "Berney Arms", category: "British railway station" },
  { name: "Exeter St David's", category: "British railway station" },
  { name: "Martins Heron", category: "British railway station" },
  { name: "Singer", category: "British railway station" },
  { name: "St Leonards Warrior Square", category: "British railway station" },

  // Music
  { name: "Amon Amarth", category: "Death metal band" },
  { name: "Cannibal Corpse", category: "Death metal band" },
  { name: "Einherjer", category: "Death metal band" },

  { name: "Pablo Honey", category: "Radiohead album" },
  { name: "Kid A", category: "Radiohead album" },
  { name: "Amnesiac", category: "Radiohead album" },
  { name: "The King of Limbs", category: "Radiohead album" },

  { name: "Ghostface Killah", category: "Wu-Tang Clan member" },
  { name: "Method Man", category: "Wu-Tang Clan member" },
  { name: "Ol' Dirty Bastard", category: "Wu-Tang Clan member" },
  { name: "Raekwon", category: "Wu-Tang Clan member" },

  { name: "Kowdeen", category: "So Solid Crew member" },
  { name: "Lisa Maffia", category: "So Solid Crew member" },
  { name: "Thug Angel", category: "So Solid Crew member" },

  { name: "Aida", category: "Verdi opera" },
  { name: "La Traviata", category: "Verdi opera" },
  { name: "Nabucco", category: "Verdi opera" },
  { name: "Oberto, Conte di San Bonifacio", category: "Verdi opera" },

  // Nature
  { name: "Bald Uakari", category: "Species of monkey" },
  { name: "Emperor Tamarin", category: "Species of monkey" },
  { name: "Gelada", category: "Species of monkey" },
  { name: "Gray Langur", category: "Species of monkey" },
  { name: "Heck's Macaque", category: "Species of monkey" },
  { name: "Red-shanked Douc", category: "Species of monkey" },

  { name: "Crack Willow", category: "Species of tree" },
  { name: "Devon Whitebeam", category: "Species of tree" },
  { name: "Silver Birch", category: "Species of tree" },
  { name: "Sitka Spruce", category: "Species of tree" },

  { name: "Cinnamon Bittern", category: "Species of water bird" },
  { name: "Goliath Heron", category: "Species of water bird" },
  { name: "Dimorphic Egret", category: "Species of water bird" },
  { name: "Intermediate Egret", category: "Species of water bird" },
  { name: "Lava Gull", category: "Species of water bird" },
  { name: "Least Tern", category: "Species of water bird" },
  { name: "Magellanic Plover", category: "Species of water bird" },
  { name: "Malayan Night Heron", category: "Species of water bird" },

  { name: "Adaptaur", category: "Australian cattle breed" },
  { name: "Belmont Red", category: "Australian cattle breed" },
  { name: "Droughtmaster", category: "Australian cattle breed" },
  { name: "Murray Grey", category: "Australian cattle breed" },
  { name: "Square Meater", category: "Australian cattle breed" },

  // History
  { name: "Lamassu", category: "Assyrian deity" },

  { name: "Bristol Beaufort", category: "World War II bomber plane" },
  { name: "Douglas Dolphin", category: "World War II flying boat" },
  { name: "Grumman Goblin", category: "World War II fighter plane" },
  { name: "Martin Baltimore", category: "World War II bomber plane" },
  { name: "Vickers Warwick", category: "World War II bomber plane" },

  { name: "Caligula", category: "Roman emperor" },
  { name: "Domitian", category: "Roman emperor" },
  { name: "Marcus Aurelius", category: "Roman emperor" },
  { name: "Trajan", category: "Roman emperor" },
  { name: "Vespasian", category: "Roman emperor" },

  { name: "Black Spread Eagle", category: "Royal Navy ship of the line" },
  { name: "Constant Warwick", category: "Royal Navy ship of the line" },
  { name: "House of Sweeds", category: "Royal Navy ship of the line" },
  { name: "Pendennis", category: "Royal Navy ship of the line" },

  { name: "Chester A. Arthur", category: "US president" },
  { name: "Martin Van Buren", category: "US president" },
  { name: "Millard Fillmore", category: "US president" },
  { name: "Rutherford B. Hayes", category: "US president" },

  // Warhammer
  { name: "Argel Tal", category: "Warhammer character" },
  { name: "Be'lakor", category: "Warhammer character" },
  { name: "Ferrus Manus", category: "Warhammer character" },
  { name: "Fulgrim", category: "Warhammer character" },
  { name: "Ibram Gaunt", category: "Warhammer character" },
  { name: "Jaghatai Khan", category: "Warhammer character" },
  { name: "Korghos Khul", category: "Warhammer character" },
  { name: "Lady Olynder", category: "Warhammer character" },
  { name: "Leman Russ", category: "Warhammer character" },
  { name: "Lorgar", category: "Warhammer character" },
  { name: "Mortarion", category: "Warhammer character" },
  { name: "Nagash", category: "Warhammer character" },
  { name: "One-Eyed Grunnock", category: "Warhammer character" },
  { name: "Perturabo", category: "Warhammer character" },
  { name: "Roboute Guilliman", category: "Warhammer character" },
  { name: "Rogal Dorn", category: "Warhammer character" },
  { name: "Vandus Hammerhand", category: "Warhammer character" },

  // Star Wars
  { name: "Ahsoka Tano", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Bail Organa", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Biggs Darklighter", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Cad Bane", category: "Star Wars character" },
  { name: "Dexter Jettster", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Droopy McCool", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Ezra Bridger", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Grand Admiral Thrawn", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Jan Dodonna", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Kit Fisto", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Lama Su", category: "Star Wars character" },
  { name: "Max Rebo", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Quinlan Vos", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Salacious B. Crumb", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Sheev Palpatine", category: "Star Wars character", exclude: ["Star Wars species"] },
  { name: "Wedge Antilles", category: "Star Wars character", exclude: ["Star Wars species"] },

  { name: "Mon Calamari", category: "Star Wars species" },
  { name: "Togruta", category: "Star Wars species" },
  { name: "Twi'lek", category: "Star Wars species" },

  // Literature
  { name: "Bestiality Carter", category: "Discworld character" },
  { name: "Carrot Ironfoundersson", category: "Discworld character" },
  { name: "Esme Weatherwax", category: "Discworld character" },
  { name: "Foul Ole Ron", category: "Discworld character" },
  { name: "Havelock Vetinari", category: "Discworld character" },
  { name: "Mustrum Ridcully", category: "Discworld character" },
  { name: "Ponder Stibbons", category: "Discworld character" },
  { name: "Sybil Ramkin", category: "Discworld character" },
  { name: "Tiffany Aching", category: "Discworld character" },
  { name: "Twoflower", category: "Discworld character" },
  { name: "Visit-The-Infidel-With-Explanatory-Pamphlets", category: "Discworld character" },
  { name: "Wallace Sonky", category: "Discworld character" },

  { name: "Slartibartfast", category: "Hitchiker's Guide character" },
  { name: "Zaphod Beeblebrox", category: "Hitchiker's Guide character" },

  { name: "Bingo Little", category: "P.G. Wodehouse character" },
  { name: "Gussie Fink-Nottle", category: "P.G. Wodehouse character" },
  { name: "Roderick Spode", category: "P.G. Wodehouse character" },
  { name: "Stilton Cheesewright", category: "P.G. Wodehouse character" },
  { name: "Tuppy Glossop", category: "P.G. Wodehouse character" },

  // Miscellaneous TV
  { name: "Alan Partridge", category: "Steve Coogan character" },
  { name: "Hans Gruber", category: "Die Hard villain" },

  // Sport
  { name: "Chuck Bednarik", category: "NFL hall of famer" },
  { name: "Dick LeBeau", category: "NFL hall of famer" },
  { name: "Mac Speedie", category: "NFL hall of famer" },
  { name: "Marshall Faulk", category: "NFL hall of famer" },

  { name: "Anfield", category: "English football stadium" },
  { name: "Craven Cottage", category: "English football stadium" },
  { name: "Ewood Park", category: "English football stadium" },
  { name: "Molineux", category: "English football stadium" },
  { name: "The Hawthorns", category: "English football stadium" },
  { name: "The Valley", category: "English football stadium" },
  { name: "Turf Moor", category: "English football stadium" },
  { name: "Vicarage Road", category: "English football stadium" },
  { name: "Villa Park", category: "English football stadium" },
 
  { name: "Bastian Schweinsteiger", category: "Football world cup winner" },
  { name: "Dino Zoff", category: "Football world cup winner" },
  { name: "Garrincha", category: "Football world cup winner" },
  { name: "Gerd Müller", category: "Football world cup winner" },
  { name: "Hege Riise", category: "Football world cup winner" },
  { name: "Hope Solo", category: "Football world cup winner" },
  { name: "Mia Hamm", category: "Football world cup winner" },
  { name: "Nobby Stiles", category: "Football world cup winner" },

  { name: "The Belfry", category: "Ryder Cup host venue" },
  { name: "Valderrama", category: "Ryder Cup host venue" },
  { name: "Whistling Straits", category: "Ryder Cup host venue" },

  // Video games
  { name: "Seath the Scaleless", category: "Dark Souls boss" },

  // Food
  { name: "Caciocavallo Silano", category: "PDO region-specific foodstuff" },
  { name: "Carne Maronesa", category: "PDO region-specific foodstuff" },
  { name: "Hushållsost", category: "PDO region-specific foodstuff" },
  { name: "Kalix Löjrom", category: "PDO region-specific foodstuff" },
  { name: "Lakeland Herdwick", category: "PDO region-specific foodstuff" },
  { name: "Ossau-Iraty", category: "PDO region-specific foodstuff" },
  { name: "Single Gloucester", category: "PDO region-specific foodstuff" },
  { name: "Sura Kees", category: "PDO region-specific foodstuff" },
  { name: "Tiroler Speck", category: "PDO region-specific foodstuff" },
  { name: "Yorkshire Forced Rhubarb", category: "PDO region-specific foodstuff" },

  { name: "Swan with Two Necks", category: "CAMRA Pub of the Year" },

  { name: "Black Dog Freddy", category: "CAMRA Champion Beer of Britain" },
  { name: "Monkman's Slaughter", category: "CAMRA Champion Beer of Britain" },
  { name: "Norman's Conquest", category: "CAMRA Champion Beer of Britain" },
  { name: "Rutland Panther", category: "CAMRA Champion Beer of Britain" },

  // Miscellaneous
  { name: "Ångström", category: "Unit of length" },
  { name: "Jonaxel", category: "IKEA shelving" },
  { name: "Kallax", category: "IKEA shelving" },
  { name: "Parsec", category: "Unit of length" },
  { name: "Sharonfrukt", category: "IKEA homeware" },
  { name: "Uppdatera", category: "IKEA cutlery tray" },
]

export const Categories: string[] = [...new Set(Things.map(x => x.category))]