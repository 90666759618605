import React from 'react';
import _ from 'lodash';
import './App.css';
import { Categories, CategoryExclusions, TCategory, Things, TQuestion, TThing } from './things';
import { version } from './version';
import { logPageView, logToGA } from './analytics';

function App() {
  const [failed, setFailed] = React.useState<boolean>(false)

  React.useEffect(() => {
    logPageView()
  }, [])

  return (
    <div className={`App ${ failed ? "failed" : "" }`}>
      <QuizComponent setFailed={ setFailed }/>
      <footer>
        <p>
          <span>v{ version }:{ Things.length }</span><span><a href="https://twitter.com/rogue_michael">@rogue_michael</a></span>
        </p>
      </footer>
    </div>
  );
}

const useStateWithLocalStorage = (localStorageKey: string, defaultValue: any) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(localStorageKey) || JSON.stringify(defaultValue))
  )

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [localStorageKey, value])

  return [value, setValue]
}

interface IQuizProps {
  setFailed(e:any): void
}

const QuizComponent: React.FC<IQuizProps> = props => {
  const [history, setHistory] = useStateWithLocalStorage('history', [])
  const clearHistory = ():void => setHistory([])

  const [score, setScore] = useStateWithLocalStorage('score', 0)
  const incrementScore = ():void => {
    const newScore = score + 1
    setScore(newScore)
    setHighScore(_.max([newScore, highScore]) || 0)
  }

  const [highScore, setHighScore] = useStateWithLocalStorage('highScore', 0)

  const [question, setQuestion] = useStateWithLocalStorage('question', {})
  const unsetQuestion = ():void => setQuestion({})

  const [previousScore, setPreviousScore ] = useStateWithLocalStorage('previousScore', 0)
  const [previousQuestion, setPreviousQuestion] = useStateWithLocalStorage('previousQuestion', {})

  const handleCorrectAnswer = (e: any) => {
    e.target.blur()
    incrementScore()
    unsetQuestion()
    setPreviousQuestion(question)
    logToGA({
      category: `${question.thing.name}`,
      action: `Correct guess`,
      label: `${score + 1}`,
    })
  }

  const handleIncorrectAnswer = (e: any) => {
    e.target.blur()
    clearHistory()
    unsetQuestion()
    setPreviousQuestion(question)
    setPreviousScore(score)
    setScore( 0 )
    logToGA({
      category: `${question.thing.name}`,
      action: `Incorrect guess`,
      label: `${question.otherCategory}`,
    })
    props.setFailed(true)
    setTimeout(() => {
      props.setFailed(false)
    }, 200)
  }

  const GetNewQuestion = ():TQuestion => {
    const newThing =_.sample(remainingThings) as TThing
    const categoriesToExclude = [...newThing.exclude || [], ...CategoryExclusions[newThing.category] || [], newThing.category]
    const otherCategory = _.sample(Categories.filter(x => !categoriesToExclude.includes(x))) as TCategory
    const leftIsCorrect = _.sample([true, false]) as boolean
    setHistory([...history, newThing.name])
    return { thing: newThing, otherCategory: otherCategory, leftIsCorrect: leftIsCorrect }
  }
  const remainingThings = Things.filter(x => !history.includes(x.name))
  if (!Object.keys(question).length && remainingThings.length) setQuestion(GetNewQuestion())

  return (
    <><div className="quiz">
      <div className="question">
        { Object.keys(question).length ?
          <QuestionComponent question={ question } handleCorrectAnswer={ handleCorrectAnswer } handleIncorrectAnswer={ handleIncorrectAnswer }/>
        :
          <>
            <h1>VICTORY!</h1>
            <p>That's all the questions there are!</p>
          </>
        }
      </div>
      <div className='scores'>
        <p>Score: <span className={`score ${ score ? "" : "score-zero" }`}>{ score }</span></p>
        <p>High score: <span className={`${ score === highScore ? "high-score-current" : "" }`}>{ highScore }</span></p>
      </div>
    </div>
    { Object.keys(previousQuestion).length && !score ? <PreviousQuestionComponent question={ previousQuestion } score={ previousScore } highScore={ highScore } /> : <></> }
    </>
  )
}

interface IQuestionProps {
  question: TQuestion
  handleCorrectAnswer(e:any): void
  handleIncorrectAnswer(e:any): void
}

const QuestionComponent: React.FC<IQuestionProps> = props => {
  const { question, handleCorrectAnswer, handleIncorrectAnswer } = props
  const { thing, otherCategory, leftIsCorrect } = question

  const leftAnswer = leftIsCorrect ? thing.category : otherCategory
  const rightAnswer = leftIsCorrect ? otherCategory : thing.category

  return (<>
    <h1>{ thing.name }</h1>
    <p>
      <AnswerComponent handler={ leftIsCorrect ? handleCorrectAnswer : handleIncorrectAnswer } value={ leftAnswer } />
       <span className="mobile-break"></span> or <span className="mobile-break"></span>
      <AnswerComponent handler={ leftIsCorrect ? handleIncorrectAnswer : handleCorrectAnswer} value={ rightAnswer } />?
    </p>
  </>)
}

interface IAnswerProps {
  value: string
  handler(e:any): void
}

const AnswerComponent: React.FC<IAnswerProps> = props => {
  return (
    <button className="Answer-link" onClick={ props.handler } type="button">{ props.value }</button>
  )
}

interface IPreviousQuestionProps {
  question: TQuestion
  score: number
  highScore: number
}

const PreviousQuestionComponent: React.FC<IPreviousQuestionProps> = props => {
  const { question, score, highScore } = props
  const { leftIsCorrect, thing, otherCategory } = question
  const isHighScore: boolean = score >= highScore

  return (
    <div className='wrong-answer'>
      <p>{ thing.name }&ensp;&ndash;&ensp;{
        leftIsCorrect ?
          <span className="correct">{ thing.category }</span>
        :
          <span className="incorrect">{ otherCategory }</span>
        } or {
        leftIsCorrect ?
          <span className="incorrect">{ otherCategory }</span>
        :
          <span className="correct">{ thing.category }</span>
        }?
      </p>
      <p>Score: <span className={`score ${ isHighScore ? "" : "score-failed" }`}>{ score }</span></p>
    </div>
  )
}

export default App;
